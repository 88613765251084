<template>
  <div class="min-h-full flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" src="@/assets/CruzControl_Logo_Small_Icon.svg" alt="Workflow" />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">GOODBYE</h2>
        </div>
        <div class="mt-8" v-if="submitting">
          <div class="mt-6">
            <LoadingSpinner class="mt-10" />
          </div>
        </div>
        <div class="mt-8">
          <div class="mt-6">
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-screen w-full object-cover" src="@/assets/ferrari_wash.jpg" alt="" />
    </div>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'
import LoadingSpinner from "@/components/LoadingSpinner";
import {mapMutations} from "vuex";


export default {
  name: "logoutPage",
  title: "Cruz Control | Logout",
  components: {
    LoadingSpinner
  },
  data() {
    return {
      submitting: false,
      showError: false,
      errorMsg: "",
      noConnection: false,
      accessToken: '',
      email: "",
      password: "",
    }
  },
  methods: {
    ...mapMutations(["setUser", "setAccessToken", "resetState"]),
    async signOut() {
      try {
        await Auth.signOut().then(async () => {
          await this.resetState();
          await this.$router.push('/login');
        });
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  },
  mounted() {
    this.signOut();
  }
}
</script>

<style scoped>

</style>