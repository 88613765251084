<template>
  <div id="app" class="flex-1 overflow-visible max-h-full">
    <header-component v-if="requiresAuth"/>
    <div :class="shouldAddPadding">
      <header v-if="requiresAuth"/>
      <main>
        <div :class="isDashboard">
          <router-view/>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/headerComponent";
export default {
  name: 'App',
  components: {
    HeaderComponent,
    },
  computed: {
    requiresAuth() {
      return this.$route.meta.requiresAuth;
    },
    shouldAddPadding() {
      return this.$route.meta.requiresAuth ? "py-10" : "";
    },
    isDashboard() {
      return this.$route.meta.requiresAuth ? "max-w-7xl mx-auto sm:px-6 lg:px-8" : "";
    }
  },
}
</script>

<style>
</style>
