<template>
  <div @click.prevent="setTenantAndNavigate" class="block hover:bg-gradient-to-r from-gray-50 to-gray-100 bg-white m-2 shadow-md rounded">
    <div class="px-4 py-4 sm:px-6">
      <button class="text-xl font-medium text-primary-400 truncate sm:text-center">
        {{ source.tenant_name }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "tenantCard",
  props: {
    index: { // index of current item
      type: Number
    },
    source: { // here is: {uid: 'unique_1', text: 'abc'}
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    ...mapMutations(["setActiveTenantId"]),
    async setTenantAndNavigate() {
      await this.setActiveTenantId(this.source.id);
      location.href = this.source.tenant_url;
    }
  }
}
</script>

<style scoped>

</style>