<template>
  <div class="min-h-full flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" src="@/assets/CruzControl_Logo_Small_Icon.svg" alt="Workflow" />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">FORGOT PASSWORD</h2>
        </div>
        <section class="mt-8" v-if="success">
          <p class="mt-8 block text-sm font-medium text-gray-700 uppercase">Your password was successfully reset.</p>
          <router-link to="/login">
            <button type="button" class="mt-8 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">RETURN TO LOGIN</button>
          </router-link>
        </section>
        <div class="mt-8" v-if="!sentCode && !submitting">
           <div class="mt-6">
            <form @submit.prevent="submitPasswordReset" class="space-y-6">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700 uppercase"> Email address </label>
                <div class="mt-1">
                  <input id="email" name="email" type="email" autocomplete="email" required="true" v-model="email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>
              <div>
                <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">SUBMIT</button>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-8" v-if="submitting">
          <div class="mt-6">
          <LoadingSpinner class="mt-10" />
          </div>
        </div>
        <div class="mt-8" v-if="sentCode && !success">
          <div class="mt-6">
            <form @submit.prevent="submitPasswordReset" class="space-y-6">
              <div>
                <div v-if="showError"  class="rounded-md bg-red-50 p-4">
                  <p class="text-sm font-regular text-red-700 animate-shake whitespace-pre break-all uppercase"> {{ errorMsg }} </p>
                </div>
                <label for="verificationCode" class="block text-sm font-medium text-gray-700 uppercase">
                  Please enter the verification code sent to
                  <label class="text-primary-400">{{ email }} </label>
                </label>
                <div class="mt-1">
                  <input id="verificationCode"
                         name="verificationCode"
                         type="string"
                         autocomplete="verificationCode"
                         required="true"
                         v-model="code"
                         class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>
              <div v-if='passwordValidation.errors.length > 0' class="rounded-md bg-blue-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <font-awesome-icon icon="fa-solid fa-info-circle" class="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <div>
                      <h2>PASSWORD HINT</h2>
                      <p class="text-sm" v-for='error in passwordValidation.errors' :key="error">{{error}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label for="newPassword" class="block text-sm font-medium text-gray-700 uppercase">New Password </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input id="newPassword"
                           name="newPassword"
                           :type="showNew ? 'password' : 'text'"
                           required
                           v-model="newPassword"
                           :disabled="!codeIsValid"
                           class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:opacity-50">
                    <button type="button" @click="showNew = !showNew" :disabled="!codeIsValid" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:opacity-50">
                      <font-awesome-icon icon="fa-solid fa-eye" class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <label for="confirmPassword" class="block text-sm font-medium text-gray-700 uppercase">Confirm Password </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input id="confirmPassword"
                           name="confirmPassword"
                           :type="showConfirm ? 'password' : 'text'"
                           required
                           :disabled="!newPassword"
                           v-model="confirmPassword"
                           v-on:keyup="validateConfirmPassword"
                           @blur.prevent="validateConfirmPassword"
                           class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:opacity-50">
                    <button type="button" @click="showConfirm = !showConfirm" :disabled="!newPassword" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:opacity-50">
                      <font-awesome-icon icon="fa-solid fa-eye" class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <button type="submit"
                        :disabled="!canSubmit"
                        class="w-full bg-secondary flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 uppercase disabled:hover:bg-secondary disabled:opacity-50">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-screen w-full object-cover" src="@/assets/ferrari_wash.jpg" alt="" />
    </div>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "passwordReset",
  title: "Cruz Control | Password Reset",
  components: {
    LoadingSpinner
  },
  data() {
    return {
      rules: [
        { message:'Contains at least 1 lowercase letter.', regex:/[a-z]+/ },
        { message:"Contains at least 1 uppercase letter.",  regex:/[A-Z]+/ },
        { message:"Contains at least 1 number.", regex:/[0-9]+/ },
        { message:"Contains at least 1 special character.", regex:/(?=.*[ -/:-@[-`{-~]{1,})+/ },
        { message:"8 characters minimum.", regex:/.{8,}/ }
      ],
      email: '',
      code: '',
      newPassword: '',
      confirmPassword: '',
      sentCode: false,
      submitting: false,
      showError: false,
      errorMsg: null,
      success: false,
      showNew: true,
      showConfirm: true,
    }
  },
  computed: {
    isNewPasswordValid() {
      if (!this.newPassword) return true
      return !!(this.newPassword.match(/[a-z]/g)
          && this.newPassword.match(/[A-Z]/g)
          && this.newPassword.match(/[0-9]/g))
    },
    passwordsFilled () {
      return (this.newPassword !== '' && this.confirmPassword !== '')
    },
    passwordValidation () {
      let errors = []
      for (let condition of this.rules) {
        if (!condition.regex.test(this.newPassword)) {
          errors.push(condition.message)
        }
      }
      if (errors.length === 0) {
        return { valid:true, errors }
      } else {
        return { valid:false, errors }
      }
    },
    passwordsMatch() {
      return this.confirmPassword === this.newPassword
    },
    codeIsValid() {
      return this.code !== ""
    },
    canSubmit(){
      return !this.submitting && this.passwordValidation.valid && this.passwordsFilled && this.passwordsMatch;
    }
  },
  methods: {
    validateConfirmPassword () {
      if (!this.passwordsMatch && this.confirmPassword.length >= 8) {
        this.showError = true;
        this.errorMsg = "New And Confirm Password do not match"
      } else {
        this.showError = false;
      }
    },
    submitPasswordReset() {
      if (!this.sentCode) this.getVerificationCode()
      else this.passwordResetComplete()
    },
    getVerificationCode() {
      this.submitting = true
      this.errorMsg = null
      Auth.forgotPassword(this.email)
          .then(response => {
            console.log(response)
            this.submitting = false
            this.sentCode = true
          })
          .catch(error => {
            console.log(error)
            if(error.toString().includes("NotAuthorizedException: ")){
              this.errorMsg = error.toString().split("NotAuthorizedException: ")[1]
              this.showError = true
              this.submitting = false
            }
            if(error.toString().includes("LimitExceededException: ")){
              this.errorMsg = error.toString().split("LimitExceededException: ")[1]
              this.showError = true
              this.submitting = false
            }
        }
      )
    },
    passwordResetComplete() {
      this.submitting = true
      this.errorMsg = null
      Auth.forgotPasswordSubmit(this.email, this.code, this.newPassword)
        .then(response => {
          if (response) {
            this.success = true
            this.submitting = false
            this.showError = false
          }
        })
        .catch(error => {
        console.log(error)
        if(error.toString().split("NotAuthorizedException: ")){
          this.errorMsg = error.toString().split("NotAuthorizedException: ")[1]
          this.showError = true
          this.submitting = false
        }
        if(error.toString().split("LimitExceededException: ")){
          this.errorMsg = error.toString().split("LimitExceededException: ")[1]
          this.showError = true
          this.submitting = false
        }
        if(error.toString().split("InvalidPasswordException: "))
        {
          this.showError = true
          this.errorMsg = error.toString().split("InvalidPasswordException: ")[1].split('Password does not conform to policy: ')[1]
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>