<template>
  <div class="bg-white px-6 lg:px-8">
    <div class="mx-auto max-w-2xl text-left">
      <img class="h-32 w-auto" src="@/assets/CruzControl_Logo_Small_Icon.svg" alt="Workflow" />
    </div>
    <div class="mx-auto max-w-2xl text-center">
      <h1 class="mt-6 font-bold text-2xl leading-8 text-gray-600 uppercase">Privacy Policy</h1>
      <a href="https://cruzcontrolapp.s3.us-east-2.amazonaws.com/documents/CruzControl+Privacy+Policy.pdf" class="mt-6 font-md text-md leading-8 text-primary uppercase">download</a>
    </div>
    <div class="mx-auto max-w-6xl text-center">
      <vue-pdf-embed
          ref="pdfRef"
          :source="source1"
          :page="page"
          :scale="3"
          @rendered="handleDocumentRender"
      />
    </div>
    <div class="mx-auto max-w-2xl text-center">
      <button :disabled="page <= 1" @click="page--">❮</button>
      {{ page }} / {{ pageCount }}
      <button :disabled="page >= pageCount" @click="page++">❯</button>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'
export default {
  name: "PrivacyPolicyComponent",
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      pageCount: 1,
      source1: 'https://cruzcontrolapp.s3.us-east-2.amazonaws.com/documents/CruzControl+Privacy+Policy.pdf',
      showAllPages: false,
    }
  },
  methods: {
    handleDocumentRender() {
      this.isLoading = false
      this.pageCount = this.$refs.pdfRef.pageCount
    },
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1
    },
  },
}
</script>

<style scoped>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

</style>