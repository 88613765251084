<template>
  <div class="min-h-full flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" src="@/assets/CruzControl_Logo_Small_Icon.svg" alt="Workflow" />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">SIGN IN</h2>
        </div>
        <div class="mt-8" v-if="submitting">
          <div class="mt-6">
            <LoadingSpinner/>
          </div>
        </div>
        <div class="mt-8" v-if="!submitting">
          <div class="mt-6">
            <form @submit.prevent="login" class="space-y-6">
              <div>
                <p v-if="showError" class="block text-sm font-regular text-red-700 animate-shake whitespace-pre"> {{ errorMsg }} </p>
                <label for="email" class="block text-sm font-medium text-gray-700 uppercase"> Email address </label>
                <div class="mt-1">
                  <input id="email" name="email" type="email" autocomplete="email" required v-model="email" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>
              <div class="space-y-1">
                <label for="password" class="block text-sm font-medium text-gray-700 uppercase"> Password </label>
                <div class="mt-1 flex rounded-md shadow-sm">
                  <div class="relative flex flex-grow items-stretch focus-within:z-10">
                    <input id="password" name="password" :type="show ? 'password' : 'text'" autocomplete="current-password" required v-model="password" class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <button type="button" @click="show = !show"  class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                      <font-awesome-icon icon="fa-solid fa-eye" class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-between">
                <div class="text-sm">
                  <a href="/reset" class="font-medium text-indigo-600 hover:text-indigo-500 uppercase"> Forgot your password? </a>
                </div>
              </div>

              <div>
                <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 uppercase">Sign in</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-screen w-full object-cover" src="@/assets/ferrari_wash.jpg" alt="" />    </div>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'
import LoadingSpinner from "@/components/LoadingSpinner";
import {mapMutations} from "vuex";
import {store} from "@/store/store";
import {getUser} from "@/services/user.service";

export default {
  name: "loginPage",
  title: "Cruz Control | Login",
  components: {
    LoadingSpinner
  },
  data() {
    return {
      submitting: false,
      showError: false,
      errorMsg: "",
      noConnection: false,
      email: "",
      password: "",
      show: true,
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    async login() {
      this.submitting = true
      this.showError = false
      Auth.signIn(this.email, this.password)
          .then(async response => {
            if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
              console.log(response)
              await store.commit("setEmail",response.challengeParam.userAttributes.email)
              await this.$router.push('/first_login')
            }
            else {
              let userData = await getUser(this.email)
              if(userData.length > 0){
                if(userData[0].active){
                  // get token from response
                  console.log(userData[0]);
                  await store.commit('setUser', userData[0])
                  await this.$router.push('/')
                }
                else if(!userData[0].active) {
                  this.errorMsg =
                      `ACCOUNT INACTIVE. \nCONTACT SUPPORT (800)-555-5555`
                  this.showError = true
                  this.submitting = false
                }
              }
              else {
                this.errorMsg = `ACCOUNT NOT FOUND`
                this.showError = true
                this.submitting = false
              }
            }
          })
          .catch(error => {
            console.log(error)
            this.errorMsg = error.toString().split("NotAuthorizedException: ")[1]
            this.showError = true
            this.submitting = false
          })
    }
  }
}
</script>

<style scoped>

</style>