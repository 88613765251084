import {createRouter, createWebHistory} from 'vue-router'
import LoginPage from "@/views/loginPage";
import DashboardPage from "@/views/dashboardPage";
import pageNotFound from "@/views/pageNotFound";
import passwordReset from "@/views/passwordReset";
import FirstLoginPage from "@/views/firstLoginPage";
import {Auth} from "aws-amplify";
import {store} from "@/store/store";
import LogoutPage from "@/views/logoutPage";
import profilePage from "@/views/profilePage";
import InactivityPage from "@/views/inactivityPage";
import PrivacyPolicyComponent from "@/views/PrivacyPolicy";




// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    {
        path: '/',
        name: "Landing",
        redirect: '/dashboard',
        title: 'Home',
        meta: { requiresAuth: true }
    },
    {
        path: '/dashboard',
        component: DashboardPage,
        name: "Dashboard",
        title: 'Dashboard',
        meta: { requiresAuth: true }
    },
    {
        path: '/profile',
        component: profilePage,
        name: "Profile",
        title: 'Profile',
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        component: LoginPage,
        name: "Login",
        title: 'Login',
        meta: { requiresAuth: false }
    },
    {
        path: '/first_login',
        component: FirstLoginPage,
        name: "First Login",
        title: 'First Login',
        meta: { requiresAuth: false }
    },
    {
        path: '/logout',
        component: LogoutPage,
        name: "Logout",
        title: 'Logout',
        meta: { requiresAuth: false }
    },
    {
        path: '/inactivity',
        component: InactivityPage,
        name: "Inactivity",
        title: 'Inactivity',
        meta: { requiresAuth: false }
    },
    {
        path: '/reset',
        component: passwordReset,
        name: "Password Reset",
        title: 'Password Reset',
        meta: { requiresAuth: false }
    },
    {
        path: '/PrivacyPolicy',
        component: PrivacyPolicyComponent,
        name: "Privacy Policy",
        title: 'Privacy Policy',
        meta: { requiresAuth: false }
    },
    {
        path: '/404',
        component: pageNotFound,
        meta: { requiresAuth: false }
    },
    {
        path: '/*',
        redirect: '/404'
    },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
    if(to.meta.requiresAuth) {
        Auth.currentSession()
            .then(data => {
                console.log(data)
                next()
            })
            .catch(err => {
                console.log(err)
                if(err.message === 'Refresh Token has expired'){
                    next('/inactivity')
                }
                else{
                    next('/login')
                }
            });
    }
    else
    {
        store.commit("setCurrentRoute", to.path);
        next()
    }
})

router.beforeEach((to, from, next) => {
    document.title = to.name || 'CRUZ CONTROL | PORTAL';
    next();
});

export default router