<template>
  <div class="min-h-full">
    <main class="py-10">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 text-center sm:py-0">
        <h1 class="text-3xl leading-6 font-medium text-gray-900 uppercase py-5">My Organizations</h1>
      </div>
      <AcceptableUse v-if="!accepted" :currentVersion="eulaVersion"/>
      <div v-if="noTenants" class="py-4">
        <div class="rounded-md bg-yellow-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">ATTENTION</h3>
              <div class="mt-2 text-sm text-yellow-700">
                <p>You have not been assigned an organization.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white overflow-hidden sm:px-5">
        <ul role="list" class="divide-y divide-gray-200">
          <ul role="list" class="space-y-3">
            <virtual-list style="height: 100%; overflow-y: auto;"
                          :data-key="'id'"
                          :data-sources="tenants"
                          :data-component="itemComponent"
                          :keeps="99999"
            />
          </ul>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import { ExclamationIcon } from '@heroicons/vue/solid'
import {getEmployeesByUserId} from "@/services/user.service";
import TenantCard from "@/components/tenantCard";
import VirtualList from "vue3-virtual-scroll-list";
import AcceptableUse from "@/components/Modal/AcceptanceTerms";
export default {
  name: "dashboardPage",
  title: "Dashboard",
  components: {
    AcceptableUse,
    'virtual-list': VirtualList,
    ExclamationIcon
  },
  data() {
    return {
        itemComponent: TenantCard,
        tenants: [],
        noTenants: false,
        accepted: false,
        eulaVersion: 1.0
    }
  },
  computed: {
    ...mapGetters(
        {
          getUser: "getUser",
          getLegalAcceptance: "getLegalAcceptance"
        }
    ),
  },
  methods: {
    async checkEULA() {
      if(this.getUser.accepted_version < this.eulaVersion || this.getUser.accepted_agreement === false)
      {
        this.accepted = false;
      }
      else
      {
        this.accepted = true;
        await this.getEmployees();
      }
    },
    async getEmployees() {
      this.tenants = (await getEmployeesByUserId(this.getUser.id))
      if(this.tenants.length === 1)
      {
        location.href = this.tenants[0].tenant_url;
      }
      else if(!this.tenants.length >= 1){
        this.noTenants = true;
      }
    }
  },
  beforeMount() {
    this.checkEULA();
  },
  watch: {
    async getLegalAcceptance (val) {
      if (val === true) {
        await this.getEmployees();
      }
    }
  }
}
</script>

<style scoped>

</style>