module.exports = {
    Auth: {
        
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-2',
    
        // OPTIONAL - Amazon Cognito User Pool ID
        //DEV
        userPoolId: 'us-east-2_QZEsuVJkz',
        
        //PROD
        //userPoolId: process.env.AWS_COGNITO_POOL_ID,
        
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        //DEV
        //userPoolWebClientId: '10ikidunalv1eke7jid2gp9bok',
                
        //PROD
        userPoolWebClientId: '64prle8qleuppo8730v44r16bn',
    
        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: false,
    
        // // OPTIONAL - Configuration for cookie storage
        // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
         cookieStorage: {
        // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
             domain: '.cruzcontrol.app',
        // // OPTIONAL - Cookie path
             path: '/',
        // // OPTIONAL - Cookie expiration in days
             expires: 365,
        // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
             sameSite: "lax",
        // // OPTIONAL - Cookie secure flag
        // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
             secure: true
         },
    
        // // OPTIONAL - customized storage object
        // storage: MyStorage,
        
        // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',
    
        // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },
    
        //  // OPTIONAL - Hosted UI configuration
        // oauth: {
        //     domain: 'your_cognito_domain',
        //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //     redirectSignIn: 'http://localhost:3000/',
        //     redirectSignOut: 'http://localhost:3000/',
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        // }
    }
};
