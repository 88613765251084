import { createApp } from 'vue'
import App from './App.vue'
import './styles/index.css'
import router  from "@/routes/router";
import { store } from "@/store/store";
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
Amplify.configure(awsConfig);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faEye, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faEye)
library.add(faInfoCircle)

const app = createApp(App)
app.use(router)
app.use(store);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
