import {customFetch, customFetch_PUT} from "@/util/customFetch";


export async function getUser(email) {
    return customFetch(`/api/v1/users/${email}`,'GET')
}

export async function getEmployeesByUserId(user_id) {
    return customFetch(`/api/v1/employees/?user=${user_id}`,'GET')
}

export async function getAllNetworkingDevices(tenant_id) {
    return fetch("/api/v1/networkDevices/?tenant=" + tenant_id, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},

    }).then(res => res.json()
        .then(data => {return data})
    )
}

export async function updateEULAAcceptance(user_id, version) {
    return customFetch_PUT(`/api/v1/users/eula/${user_id}`, { "version": version })
}