<template>
  <div class="min-h-full flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-12 w-auto" src="@/assets/CruzControl_Logo_Small_Icon.svg" alt="Workflow" />
          <h2 class="mt-6 text-3xl font-extrabold text-gray-900">INACTIVITY</h2>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <div class="space-y-1">
              <label class="block text-sm font-medium text-gray-700"> You have been signed out due to inactivity. </label>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <div class="space-y-1">
              <div>
                <a type="button" href="/login" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sign in</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-screen w-full object-cover" src="@/assets/ferrari_wash.jpg" alt="" />
    </div>
  </div>
</template>

<script>

import { Auth } from 'aws-amplify'
import {mapMutations} from "vuex";


export default {
  name: "logoutPage",
  title: "Cruz Control | Logout",
  components: {
  },
  data() {
    return {
      submitting: false,
      showError: false,
      errorMsg: "",
      noConnection: false,
      accessToken: '',
      email: "",
      password: "",
    }
  },
  methods: {
    ...mapMutations(["setUser", "setAccessToken", "resetState"]),
    async signOut() {
      try {
        await Auth.signOut().then(async () => {
          await this.resetState();
          await this.$router.push('/login');
        });
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  }
}
</script>

<style scoped>

</style>