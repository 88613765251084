import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

const initialState = () => ({
    cognitoUser: {
        email: null,
        accessToken: null
    },
    user: {
        id: null,
        email: null,
        firstname: null,
        lastname: null,
        active_tenant_id: null,
        accepted_agreement: null,
        accepted_timestamp: null,
        accepted_version: null,
    },
    currentRoute: null
})


export const store = createStore({
    plugins: [createPersistedState({
        key: 'cruzcontrol',
        paths:["user"],
        storage: {
            getItem: (key) => {
                    return Cookies.get(key);
            },
            // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
            setItem: (key, value) =>
                Cookies.set(key, value, { expires: 365, domain: '.cruzcontrol.app', secure: true }),
            removeItem: key => Cookies.remove(key)
        }
    })],
    state: initialState,
    getters: {
        getUser: (state) => state.user,
        getFullName: (state) => `${state.user.firstname} ${state.user.lastname}`,
        getEmail: (state) => state.user.email,
        getCurrentRoute: (state) => state.currentRoute,
        getLegalAcceptance: state => state.user.accepted_agreement
    },
    mutations: {
        async resetState (state) {
            const initial = initialState()
            Object.keys(initial).forEach(key => { state[key] = initial[key] })
            Cookies.remove('cruzcontrol')
        },
        async setCurrentRoute (state, newRoute) {
            state.currentRoute = newRoute
        },
        async setEmail(state, newEmail){
          state.user.email = newEmail;
        },
        async setUser(state, userData) {
            state.user.id = userData.id;
            state.user.email = userData.email;
            state.user.firstname = userData.firstname;
            state.user.lastname = userData.lastname;
            state.user.accepted_agreement = userData.accepted_agreement;
            state.user.accepted_timestamp = userData.accepted_timestamp;
            state.user.accepted_version = userData.accepted_version;
        },
        async setAccessToken(state, newToken) {
            console.log(newToken);
            state.cognitoUser.accessToken = newToken;
        },
        async setActiveTenantId(state, newTenant) {
            state.user.active_tenant_id = newTenant;
        }
    }
})
