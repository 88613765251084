const {Auth} = require("aws-amplify");

async function fetchCurrentUserData() {
    return (await Auth.currentSession()).getAccessToken().getJwtToken()
}

async function customFetch(url, method, body){
    let accessToken = await fetchCurrentUserData();
    try{
        return fetch(url, {
            method: `${method}`,
            headers: {'Content-Type': 'application/json', 'accesstoken': `${accessToken}`},
            body: body
        })
            .then(res => res.json()
                .then(data => { return data })
            )
    } catch(e)
    {
        console.log(e)
    }

}

async function customFetch_POST(url, body){
    let accessToken = await fetchCurrentUserData();
    try{
        return fetch(url, {
            method: `POST`,
            headers: {'Content-Type': 'application/json', 'accesstoken': `${accessToken}`},
            body: body
        })
            .then(res => res.json()
                .then(data => { return data })
            )
    } catch(e)
    {
        console.log(e)
    }

}

async function customFetch_PUT(url, body){
    let accessToken = await fetchCurrentUserData();
    try {
        return fetch(url, {
            method: `PUT`,
            headers: {'Content-Type': 'application/json', 'accesstoken': `${accessToken}`},
            body: JSON.stringify(body)
        })
            .then(res => res.json()
                .then(data => { return data })
            )
    } catch(e)
    {
        console.log(e)
    }
}

module.exports = {
    customFetch,
    customFetch_POST,
    customFetch_PUT,
    fetchCurrentUserData
}