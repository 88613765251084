<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 uppercase mb-2">Acceptance of Terms</DialogTitle>
                  <ul role="list">
                    <li>
                      <a href="https://cruzcontrolapp.s3.us-east-2.amazonaws.com/documents/CruzControl+Privacy+Policy.pdf" target="_blank" class="font-md text-xs text-primary uppercase text-center !outline-none col-span-1">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="https://cruzcontrolapp.s3.us-east-2.amazonaws.com/documents/CruzControl+Acceptable+Use+Policy.pdf" target="_blank" class="font-md text-xs text-primary uppercase text-center !outline-none col-span-1">Acceptable Use Policy</a>
                    </li>
                    <li>
                      <a href="https://cruzcontrolapp.s3.us-east-2.amazonaws.com/documents/CruzControl+Terms+of+Service.pdf" target="_blank" class="font-md text-xs text-primary uppercase text-center !outline-none col-span-1">Terms of Service</a>
                    </li>
                    <li>
                      <a href="https://cruzcontrolapp.s3.us-east-2.amazonaws.com/documents/CruzControl+Website+Terms+and+Conditions.pdf" target="_blank" class="font-md text-xs text-primary uppercase text-center !outline-none col-span-1">Terms and Conditions</a>
                    </li>
                  </ul>
                  <div class="mt-2">
                    <p class="text-xs text-gray-500">By using this website, you are hereby accepting the terms and conditions stipulated within the Terms and Conditions, Privacy Policy, and Master Services Agreement. <br/><br/>If you are not in agreement with our terms and conditions, then you should refrain from further use of our sites. <br/> <br/> In addition, your continued use of our website following the posting of any updates or changes to our terms and conditions shall mean that you agree and acceptance of such changes.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-3 pt-4">
                <div class="relative flex items-start col-span-3">
                  <div class="flex h-6 items-end">
                    <p id="acceptance-description" class="text-gray-500 text-xs text-center italic">Click here to indicate that you have read and agree to the terms presented in the Terms and Conditions agreement. </p>
                  </div>
                </div>
                <div class="relative flex justify-center col-start-2 mt-2">
                  <div class="flex h-6 items-center">
                    <input id="acceptance" aria-describedby="acceptance-description" name="acceptance" type="checkbox" @click="accept = true" class="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600" />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="acceptance" class="font-medium text-gray-900">I ACCEPT</label>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button :onclick="SubmitAcceptance" :disabled="!accept" type="button" class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-500 uppercase" @click="open = false">Proceed</button>
                <router-link to="/logout">
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-500 sm:col-start-1 sm:mt-0 uppercase" @click="open = false" ref="cancelButtonRef">Decline</button>
                </router-link>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {updateEULAAcceptance} from "@/services/user.service";
import {mapGetters} from "vuex";
import {store} from "@/store/store";
export default {
  name: "AcceptableUse",
  components: {
    DialogPanel,
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    currentVersion: Number,
  },
  data() {
    return {
      open: true,
      accept: false,
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    async SubmitAcceptance() {
      await store.commit('setUser', await updateEULAAcceptance(this.getUser.id, this.currentVersion))
      this.open = false;
    }
  }
}
</script>

<style scoped>

</style>